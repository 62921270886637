.applist {
    margin: 0 0 20px;
}

.applist .appList-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.applist .appList-header .user-select-block,
.applist .appList-header .stub {
    width: 285px;
}

@media (max-width: 1024px) {
    .applist .appList-header {
        flex-direction: column;
        gap: 16px;
    }

    .applist .appList-header .stub {
        display: none;
    }

    .applist .appList-header .user-select-block {
        width: 100%;
        max-width: 500px;
        order: -1;
    }
}

.applist.hide-actions .appList-content > ul > li {
    pointer-events: none;
}

.applist.hide-actions .new-pwa-link {
    display: none;
}

.applist.hide-actions .edit-pwa-link {
    opacity: 0;
    pointer-events: none;
}

.applist .appList-content {
    max-width: 400px;
    margin: 0 auto 20px;
    margin: 0 auto;
}

.applist .appList-content > ul {
    padding: 0;
    /* min-width: 350px; */
}

.applist .appList-content > ul > li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;

    padding: 6px 0;
    word-wrap: break-word;
}
.applist > ul > li > div {
    padding: 6px 0;
}

.applist .appList-content > ul > li.header {
    background-color: #eee3db;
}
.applist .section {
    align-items: flex-end;
}

/* -------------- */

.sortable-item {
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 8px 0;
    cursor: grab;
    background-color: var(--bg-color-card);
    box-shadow: var(--box-shadow-card);
    border-radius: 6px;

    user-select: none;
}

.applist .item-content {
    display: flex;
    align-items: center;
}

.applist .item-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
}

.applist .item-name {
    font-size: 16px;
}
.applist .priority {
    font-size: 12px;
    color: #949393;
    margin-right: 15px;
}

.sortable-item .drag-handle {
    cursor: grab;
    margin: 0 15px;
}

.sortable-item .drag-handle svg {
    width: 100%;
    height: auto;
    display: flex;
}

.sortable-item .drag-handle svg path {
    fill: var(--fill-icons);
    transition: all 0.3s;
}

.sortable-item:hover .drag-handle svg path {
    fill: var(--fill-icons-hover);
}

/* PWA  */

.applist.pwa {
    max-width: none;
}

.applist .pwa-block {
    margin-top: 16px;
}

.applist .pwa-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.applist .pwa-item {
    border-radius: 6px;
    background-color: var(--bg-color-route);
    border: 1px solid var(--border-color-route);
    padding: 16px;
    box-shadow: var(--box-shadow-route);
}

.applist .pwa-item .pwa-item-content {
    display: grid;
    grid-template-columns: 80px 1fr 90px;
    gap: 10px;
    align-items: center;
}

.applist .pwa-item .pwa-item-content button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.applist .pwa-item .pwa-icon {
    padding: 5px;
    height: 80px;
    background-color: var(--bg-body);
    border-radius: 6px;
}

.applist .pwa-item .pwa-icon > div {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    border-radius: 6px;
    background-repeat: no-repeat;
}

.applist .pwa-item .pwa-name {
    font-size: 16px;
    font-weight: 600;
    word-break: break-all;
}

.applist .pwa-languages {
    height: 100%;
}

.applist .pwa-languages > div {
    display: inline-block;
}

.applist .pwa-actions {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    padding-left: 5px;
}

.applist .pwa-actions a {
    position: relative;
}

.applist .pwa-actions svg {
    width: 20px;
    height: auto;
    display: flex;
}

.applist .pwa-actions svg path {
    fill: var(--fill-icons);
    transition: all 0.3s;
}

.applist .pwa-actions svg:hover path {
    fill: var(--fill-icons-hover);
}

.pwa-block .footer {
    margin-top: 16px;
}
