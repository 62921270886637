.deeplinks {
    width: 100%;
    margin: 0 auto 20px;
}

.deeplinks .deeplinks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deeplinks .deeplinks-header .user-select-block,
.deeplinks .deeplinks-header .stub {
    width: 285px;
}

@media (max-width: 1024px) {
    .deeplinks .deeplinks-header {
        flex-direction: column;
        gap: 16px;
    }

    .deeplinks .deeplinks-header .stub {
        display: none;
    }

    .deeplinks .deeplinks-header .user-select-block {
        width: 100%;
        max-width: 500px;
        order: -1;
    }
}

.deeplinks.hide-actions .route-item-actions a:first-child {
    opacity: 0;
    pointer-events: none;
}

.deeplinks.hide-actions .filter-block .left-block a {
    display: none;
}

.deeplinks.deeplink-stats {
    max-width: 700px;
}

.deeplinks.deeplink-stats h1 {
    margin-bottom: 16px;
}

.deeplinks > ul {
    border: 1px solid #d9cfcb;
    border-radius: 3px;
    padding: 0;
    min-width: 550px;
}

.deeplinks > ul > li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;

    padding: 6px 0;
    margin: 0;
    word-break: break-all;
    word-wrap: break-word;
}
.deeplinks > ul > li > div {
    padding: 6px 0;
}
.deeplinks > ul > li:nth-child(odd) {
    background-color: #f1eee7;
}
.deeplinks > ul > li:nth-child(even) {
    background-color: #fdf8f1;
}

.deeplinks > ul > li.header {
    background-color: #efe4dc;
}
.deeplinks .header .stats {
    border-left: 1px solid #decabc;
}

.deeplinks .wideLinksCell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}
.deeplinks .wideLinksCell.cell1 {
    width: 17%;
    padding-left: 3px;
}
.deeplinks .wideLinksCell.cell2 {
    width: 59%;
}
.deeplinks .wideLinksCell > div {
    width: auto !important;
}
.deeplinks .wideLinksCell.cell1 > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}
.deeplinks .wideLinksCell.cell1 > div:first-child {
    /*border-bottom: 1px solid #e8dcd2;*/
    padding-bottom: 3px;
}
.deeplinks .wideLinksCell.cell1 > div:last-child {
    padding-top: 3px;
}
.deeplinks .wideLinksCell.cell1 .tinyItem {
    height: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.deeplinks .icon.tiny {
    min-width: 12px;
}

@media (min-width: 900px) {
    .deeplinks .tinyItem {
        max-width: max-content;
    }

    .deeplinks .tinyItem span {
        display: block;
    }

    .deeplinks .tinyItem span:last-child {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.deeplinks .deeplink {
    width: 30%;
    text-align: left;
    border-left: 1px solid #e8dcd2;
    padding-left: 10px;
    font-size: 14px;

    color: #a14800;
    cursor: text;
}
.deeplinks .deeplink .inRow {
    justify-content: left;
    align-items: start;
}
.deeplinks .deeplink .inRow > * {
    margin-right: 0px;
    margin-left: 0px;
}

.deeplinks .targetLink {
    width: 46%;
    text-align: left;
    border-left: 1px solid #e7dad1;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 12px;

    color: #555555;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.deeplinks .statsTargetLink {
    width: 65%;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 14px;
}
.deeplinks .stats2 {
    text-align: center;
    border-left: 1px solid #e2d4cc;
    padding: 8px 10px 2px;
}
.deeplinks .stats {
    width: 75px;
    text-align: center;
    border-left: 1px solid #e5dad1;
    padding: 8px 10px 2px;
}
.deeplinks .stats a {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #443b35;
}
.deeplinks .stats .zero {
    color: #b3a89e;
}
button.button-stats {
    background: url(../../../public/icons/stats.png) center center no-repeat;
    width: 25px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    margin: 0 0 0 5px;
    top: -1px;
}

.deeplinks .section {
    align-items: flex-end;
}

/*nth-child(odd)*/

.deeplinks .info {
    padding: 0;
    width: 19%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.deeplinks .info .subinfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2px;
}

.deeplinks .info .name {
    font-size: 14px;
}
.deeplinks .info .date {
    font-size: 10px;
    color: #935b2b;
}
.deeplinks .info .id {
    font-size: 10px;
    color: #cdb19d;
    overflow: visible;
    width: 30px;
}
.deeplinks .info .id-only {
    color: #935b2b;
    font-size: 14px;
}

.deeplinks > ul.info,
.deeplinks > * > ul.info {
    margin: 4px 12px;
}
.deeplinks > ul.info > li,
.deeplinks > * > ul.info > li {
    padding: 0;
    margin: 0;
}

.deeplinks .info .rightIcons {
    margin: 3px 0 0;
    width: 30px;
}
.deeplinks .info .rightIcons > * {
    float: right;
}

.otherPostbackParam {
    font-size: 14px;
    padding: 2px;
    width: 70px;
}

input:not(.form-check-input, .form-range, #login-form input, .select input),
textarea:not(.form-check-input) {
    background: var(--bg-color-input);
    border: 1px solid var(--border-color-input) !important;
    border-radius: 6px !important;
    color: var(--primary-text-color);
    transition: border-color 0.15s ease-in-out;
}

input:not(.form-check-input, .form-range, #login-form input, .select input):focus,
.textarea-wrapper:focus-within {
    border: 1px solid var(--border-color-focus-input) !important;
}

input:not(.form-check-input, .form-range, #login-form input, .select input, input:disabled):hover,
.textarea-wrapper:hover {
    border: 1px solid var(--border-color-focus-input) !important;
}

.create-edit-pwa :-webkit-autofill,
.page :-webkit-autofill {
    border: 1px solid var(--border-color-input) !important;
  -webkit-text-fill-color: var(--primary-text-color) !important;
  -webkit-box-shadow: 0 0 0 30px var(--bg-color-input) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}


.filtration-level-block {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    row-gap: 10px;
}

.filtration-level-block .radio-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.filtration-level-block .radio-group input {
    margin-top: 1px;
}

.delete-btn {
    background: none;
    padding: 0;
    border: none;
    margin-left: 5px;
    cursor: pointer;
}

.delete-btn svg {
    width: 16px;
    height: auto;
    display: flex;
}

.delete-btn svg path {
    fill: var(--fill-icons);
    transition: all 0.3s;
}

.delete-btn:hover svg path {
    fill: var(--fill-icons-hover);
}

.whitepage-options {
    margin-left: 13% !important;
    width: 87% !important;
    margin-top: 3px !important;
}

.copy-btn {
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.copy-btn svg {
    width: 20px;
    height: auto;
    display: flex;
}

.copy-btn svg path {
    fill: var(--fill-icons);
}

.copy-btn:hover svg path {
    fill: var(--fill-icons-hover);
}

/* filter block */

.filter-block {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 15px;
}

.filter-block > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filter-block .left-block {
    gap: 15px;
    justify-content: space-between;
}

.filter-block .switch-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.filter-block .react-switch.not-checked .react-switch-handle {
    background: var(--color-switch-handle) !important;
}

.filter-block .react-switch.not-checked .react-switch-bg {
    background: var(--bg-color-switch) !important;
}

.filter-block .react-switch.checked .react-switch-handle {
    background: var(--color-switch-handle-active) !important;
}

.filter-block .react-switch.checked .react-switch-bg {
    background: var(--bg-color-switch-active) !important;
}


.filter-block .push-block button {
    border: none;
    height: 38px;
    transition: all 0.3s;
    cursor: pointer;
}

.filter-block .search-block {
    position: relative;
}

.filter-block .search-block button {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    width: 18px;
    cursor: pointer;
}

.filter-block .search-block button svg {
    width: 100%;
    height: auto;
    display: flex;
}

.filter-block .search-block button svg path {
    fill: var(--fill-icons);
    transition: all 0.3s;
}
.filter-block .search-block button:hover {
    fill: var(--fill-icons-hover);
}

.filter-block .total-installs-block {
    display: flex;
    align-items: center;
    gap: 3px;
    white-space: nowrap;
}

.postback-block {
    display: flex;
    align-items: center;
    padding-left: 6px;
    gap: 10px;
}

.postback-block > div {
    display: flex;
    gap: 7px;
}

.postback-block input, .postback-block select {
    max-width: 90px;
    width: 100%;
    height: 26px;
}

@media(max-width: 560px) {
    .postback-block {
        flex-direction: column;
        align-items: start;
        gap: 5px;
    }
}

.warning-msg {
    color: var(--warning-text-color);
}

/* routes new design */
.route-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.route-item {
    border-radius: 6px;
    background-color: var(--bg-color-route);
    border: 1px solid var(--border-color-route);
    padding: 16px;
    box-shadow: var(--box-shadow-route);
}

.route-item .router-item-header {
    display: grid;
    /* grid-template-columns: 0.1fr 0.6fr 3fr 0.3fr; */

    grid-template-columns: 45px 120px 3fr 65px;
    align-items: center;
}

.route-item-id > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: max-content;
}

.route-item .route-item-id span:not(.route-item .route-item-id.with-id span) {
    display: none;
}

.route-item .route-item-id:not(.route-item .route-item-id.with-id) {
    padding-left: 6px;
}   

.route-item-date {
    color: var(--secondary-text-color);
}

.route-item-date-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 4px 0;
    position: relative;
}

.route-item-date-name .spinner {
    position: absolute;
    top: -6px;
    right: 5px;
}

.route-item-name {
    font-size: 16px;
    font-weight: 600;
}

.route-item-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.route-item-content.wideLinkDesign {
    grid-template-columns: 220px 1fr;
}

.route-item-content.wideLinkDesign.universal-type {
    grid-template-columns: 180px 1fr;
}

.route-item-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
}

.route-item-actions a {
    position: relative;
}

.route-item-actions a span {
    position: absolute;
    left: -5px;
    top: -5px;
    pointer-events: none;
    color: var(--primary-text-color);
}

.route-item-actions a span.zero {
    opacity: 0.7;
}

.route-item button {
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
}

.route-item button:active .copy-icon {
    opacity: 0.7;
}

.route-item svg {
    width: 20px;
    height: auto;
    display: flex;
}

.route-item svg path:not(.route-item .warning-icon svg path) {
    fill: var(--fill-icons);
    transition: all 0.3s;
}

.route-item .warning-icon {
    cursor: pointer;
}

.route-item svg:hover path:not(.route-item .warning-icon svg path) {
    fill: var(--fill-icons-hover);
}

.route-item-content-desktop {
    height: 100%;
}

.route-item-content {
    height: 100%;
}

.route-item-content > div {
    padding-left: 10px;
    padding-right: 10px;
    border-left: 1px solid var(--border-color-route);
}

.route-item-content .target-link {
    user-select: none;
}

.route-item-content .wide-links-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
}

.route-item-content.wideLinkDesign .wide-links-cell {
    justify-content: space-evenly;
}

.route-item .naming-not-support {
    color: var(--text-color-naming-not-support);
}

.deep-link-block {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--secondary-text-color);
}

.route-item-content-mobile {
    display: none;
}

.route-item-content .domains {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.route-item-content .tinyItem {
    display: inline-block;
}

.route-item-content .tinyItem span {
    display: inline;
}

.route-item-content .tinyItem span.icon {
    width: 12px;
    height: 12px;
    margin: 0 1px;
    display: inline-block;
}

@media (max-width: 900px) {
    .route-item-content-desktop,
    .route-item-date-name .route-item-date {
        display: none;
    }

.route-item-date-name .spinner {
        top: 50%;
        transform: translateY(-50%);
    }

    .route-item {
        cursor: pointer;
    }

    .route-item .router-item-header {
        grid-template-columns: 45px 1fr 60px;
    }

    .route-item-content-mobile {
        display: block;
        overflow: hidden;
        transition: height 0.3s ease;
    }

    .route-item-content {
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .route-item-content > div {
        padding: 0;
        border: 0;
    }

    .route-item-content .domains {
        flex-direction: row;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
    }
}

/* traffic-distribution-list */

.traffic-distribution {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.traffic-distribution-item {
}

.app-distribution-item-content {
    /*display: grid;
    grid-template-columns: 220px 2fr;
    gap: 5px;*/
    display: flex;
    flex-direction: column;
}

.traffic-distribution-item-content {
    /*display: grid;
    gap: 5px;*/
    border: 1px solid var(--border-color-subframe);
    background-color: var(--bg-color-subframe);
    border-radius: 5px;
    padding: 8px 8px;
}
.traffic-distribution-item-content.extended {
    /*grid-template-columns: 1fr 220px !important;*/
}

.traffic-distribution-item-content .sub-block {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: flex-start;
    font-size: 11px;
}
.traffic-distribution-item-content .sub-block:first-child {

}
.traffic-distribution-item-content .sub-block:last-child {
    align-items: stretch;
}
.traffic-distribution-item-content .sub-block-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
}
.traffic-distribution-item-content .sub-block-2 > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1px;
}
.traffic-distribution-item-content .target-link-container {
    padding: 2px 0 2px;
    width: 100%;
}
.traffic-distribution-item-content label {
    font-size: 11px;
    cursor: pointer;
    margin-right: 4px;
}
.traffic-distribution-item-content input[type="checkbox"] {
    margin: 2px;
}

.traffic-distribution-item-content .textarea-wrapper, .traffic-distribution-item-content textarea {
    /*border: none !important;*/
}

.traffic-distribution-item.with-range .traffic-distribution-item-content {
    grid-template-columns: 1fr 0.8fr;
}

.traffic-distribution-item-content button:not(.landing-modal button),
 .app-distribution-item-content button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 24px;
    margin-left: 10px;
}

.traffic-distribution-item-content button svg,
.app-distribution-item-content button svg {
    display: flex;
}

.traffic-distribution-item-content button svg path,
.traffic-distribution-item-content .planetIconWrapper svg,
.app-distribution-item-content button svg path {
    fill: var(--fill-icons);
}

.traffic-distribution-item-content button:hover svg path,
.traffic-distribution-item-content .planetIconWrapper:hover svg,
.app-distribution-item-content button:hover svg path {
    fill: var(--fill-icons-hover);
}

.traffic-distribution-item-content .planetIconWrapper svg {
    width: 20px;
    margin-left: 3px;
    height: auto;
    display: flex;
    cursor: pointer;
}

.traffic-distribution-item .range-block {
    display: grid;
    grid-template-columns: 1fr 67px 30px;
    align-items: center;
    gap: 5px;
    min-width: 220px;
}

.traffic-distribution-item .range-block input:last-child {
    max-width: 100%;
    padding: 3px 5px;
    margin: 2px;
}

@media (max-width: 767px) {
    .traffic-distribution-item.with-range .traffic-distribution-item-content {
        grid-template-columns: 1fr;
        gap: 0;
    }
}


.traffic-distribution-item select.small {
    height: 24px;
}
.traffic-distribution-item select:disabled {
    opacity: 0.5;
}

.traffic-distribution-select * {
    font-size: 11px;
}

.traffic-distribution-select .css-13cymwt-control,
.traffic-distribution-select .css-t3ipsp-control,
.traffic-distribution-select .css-16xfy0z-control {
    min-height: 28px;
    height: 28px;
}

.traffic-distribution-select .css-1xc3v61-indicatorContainer, 
.traffic-distribution-select .css-15lsz6c-indicatorContainer {
    padding: 3px;
}

.landing-configurator {
    position: relative;
}

.landing-configurator .error-msg {
    position: absolute;
    top: 100%;
    left: 0;
}


/* LANDING MODAL */

.landing-modal .modal-wnd-content {
    max-width: 350px;
}

#landing-modal-wrapper .edit-btn {
    margin-left: 2px;
}

#landing-modal-wrapper .edit-btn svg {
    width: 16px ;
}

#landing-modal-wrapper .edit-btn.changed svg path {
    fill: var(--secondary-text-color);
}

.landing-modal .modal-wnd-content form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  
}

.landing-modal .modal-wnd-content form  input {
    padding: 6px;
    font-size: 16px;
}

.landing-modal .modal-wnd-content  h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.landing-modal-row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 3px;
}

.landing-modal-row .readOnlyCode {
    padding: 6px;
}

.landing-modal-row label {
    font-weight: 500;
    font-size: 13px;
}

.landing-modal .warning-block {
    margin-top: -10px;
    color: var(--warning-text-color);
}

