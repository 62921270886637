.alert {
    position: fixed;
    top: 40px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 8px;
    background: var(--bg-color-alert);
    box-shadow: var(--box-shadow-alert);
    font-size: 16px;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1000;
    cursor: pointer;
}

.alert svg {
    width: 24px;
    height: auto;
    display: flex;
}

.alert .success-icon {
    fill: #4caf50;
}

.alert .error-icon {
    fill: #f44336;
}

.alert--hidden {
    opacity: 0;
    transform: translateX(100%);
    pointer-events: none;
}

.alert__icon {
    font-size: 20px;
}

.alert__message {
    flex: 1;
}
