.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 2px;
    --color: #0d6efd;
}

.loader.loader-btn {
    top: auto;
    left: auto;
    width: 100%;
    height: 100%;
    margin: auto;
}

/* WHITE */

.light .loader.white {
    --color: #fff;
}

/* SUCCESS */

.loader.success {
    --color: #28c76f;
}

/* ERROR */

.loader.error {
    --color: #ea5455;
}

/* WARNING */

.loader.warning {
    --color: #ff9f43;
}

.loader > div:last-child {
    position: relative;
    width: 80px;
    height: 15px;
    margin: 1em auto;
}

.loader.loader-btn {
    width: auto;
    height: auto;
    margin: 0;
}

.loader div div {
    position: absolute;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div div:nth-child(1) {
    left: 8px;
    animation: loader-1 0.6s infinite;
}

.loader div div:nth-child(2) {
    left: 8px;
    animation: loader-2 0.6s infinite;
}

.loader div div:nth-child(3) {
    left: 32px;
    animation: loader-2 0.6s infinite;
}

.loader div div:nth-child(4) {
    left: 56px;
    animation: loader-3 0.6s infinite;
}

@keyframes loader-1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loader-3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes loader-2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
