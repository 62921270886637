.sidebar-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    padding-bottom: 15px;
    overflow: hidden;
}

.sidebar-items-up-block {
    padding-top: 10px;
}

.sidebar-items-up-block {
    padding-top: 10px;
}

.sidebar-item svg {
    width: 100%;
    height: auto;
}

.sidebar-items .sidebar-item {
    margin: 0.125rem 0;
}

.sidebar-items .sidebar-item .link {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
    border-radius: 0.375rem;
    padding: 9px 16px;
    cursor: pointer;
    color: var(--primary-text-color);
    transition-duration: 0.3s;
    transition-property: color, background-color;
    height: 42px;
}

.sidebar-items .sidebar-item .link:hover {
    background: var(--bg-sidebar-item-hover);
}

.sidebar-items .sidebar-item .link .left-block {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sidebar-items .sidebar-item .link .left-block h3 {
    font-weight: 400;
    font-size: 15px;
    margin: 0;
    min-width: 140px;
}

.sidebar-items .sidebar-item-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: left 0.3s ease;
}

/* ICONS */
.circleIcon {
    width: 9px;
    height: 9px;
    border: 2px solid var(--fill-icons);
    border-radius: 50%;
}

.active .circleIcon {
    border: 2px solid #fff;
}

.sidebar-items .email-icon path,
.sidebar-items .users-icon path,
.sidebar-items .application-icon circle,
.sidebar-items .facebook-second-icon circle,
.sidebar-items .route-icon circle {
    stroke: var(--fill-icons);
}

.sidebar-items .active .email-icon path,
.sidebar-items .active .application-icon circle,
.sidebar-items .active .facebook-second-icon circle,
.sidebar-items .active .route-icon circle {
    stroke: #fff;
}

.sidebar-items .panel-icon path,
.sidebar-items .facebook-icon,
.sidebar-items .setting-icon path,
.sidebar-item .off-icon,
.sidebar-items .application-icon rect,
.sidebar-items .facebook-second-icon path,
.sidebar-items .route-icon path,
.sidebar-items .route-icon rect,
.sidebar-items .report-icon {
    fill: var(--fill-icons);
}

.sidebar-items .report-icon {
    width: 22px;
    height: auto;
}

.sidebar-items .active .panel-icon path,
.sidebar-items .active .facebook-icon,
.sidebar-items .active .application-icon rect,
.sidebar-items .active .facebook-second-icon path,
.sidebar-items .active .route-icon path,
.sidebar-items .route-icon rect,
.sidebar-items .active .report-icon  {
    fill: #fff;
}

/* ACTIVE */

.sidebar-items .sidebar-item.active .link {
    background: linear-gradient(
        72.47deg,
        #7367f0 22.16%,
        rgba(115, 103, 240, 0.7) 76.47%
    );
    box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.48);
    color: #fff;
}

/* GROUP ITEMS */

.sidebar-item-group-list {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
}

.sidebar-items .sidebar-item .sidebar-item-group-list .link .left-block h3 {
    min-width: 172px;
}

.sidebar-item-group .chevron {
    width: 12px;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
}

.sidebar-item-group.open .group-title h3 {
    font-weight: 600 !important;
}

.sidebar-item-group.open div.link,
.sidebar-item-group.includes-path div.link {
    background: var(--bg-sidebar-item-hover);
}

.sidebar-item-group.open .chevron {
    transform: rotate(90deg);
}

.sidebar-item-group .chevron svg {
    width: 100%;
    display: flex;
}

.sidebar-item-group .chevron svg path {
    fill: var(--fill-icons);
}

/* menu-collapsed */

.sidebar-item-group:not(.sidebar-item-group.open) .sidebar-item-group-list {
    height: 0px !important;
}

.sidebar-item h3,
.sidebar-item .chevron {
    opacity: 1;
}

@media (min-width: 1200px) {
    .menu-collapsed:not(.menu-collapsed-hover) .sidebar-item {
        overflow: hidden;
    }

    .menu-collapsed:not(.menu-collapsed-hover) .sidebar-item-group-list {
        height: 0px !important;
    }

    .menu-collapsed:not(.menu-collapsed-hover) .sidebar-item h3,
    .menu-collapsed:not(.menu-collapsed-hover) .sidebar-item .chevron {
        opacity: 0;
    }

    .menu-collapsed:not(.menu-collapsed-hover) .link {
        position: relative;
    }

    .sidebar-item-icon {
        transition: top 0.3s, left 0.3s, transform 0.3s;
    }

    .menu-collapsed:not(.menu-collapsed-hover) .link .sidebar-item-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
